import React from 'react';
import { observer, inject } from 'mobx-react';
import { SlArrowDown, SlArrowUp  } from "react-icons/sl";

// import ReactDOM from 'react-dom';
import style from './style.module.css';
import MenuItem from './MenuItem';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class SideMenu extends React.Component {
    listRef = React.createRef();

    state = {
        scroll: 0
    }

    componentDidMount() {
        this.listRef?.current?.addEventListener('scroll', () => { this.setState({scroll: this.listRef.current.scrollTop})});
    }

    render() {
        const { categories, mainColor, shop } = this.props.myShopApiStore;
        const { isMobile, isRTL } = this.props.ifaceMngr;

        if(!categories || categories.length === 1){
            return null;
        }

        const brdRadius = isMobile ? 0 : getComputedStyle(document.querySelector(':root')).getPropertyValue('--brdRadius');
        const borderRadius = !isRTL ? `0 ${brdRadius} ${brdRadius} 0` : `${brdRadius} 0 0 ${brdRadius}`;

        return (
            <div className={style.Container} style={{ borderRadius }}>
                <nav className={style.NavArrow} style={{ top: '10px' }} hidden={this.state.scroll === 0}
                    onClick={() => this.listRef.current.scrollBy(0, -100)}>
                        <SlArrowUp />
                </nav>
                <div className={style.ItemList} ref={this.listRef}>
                    {
                        categories.map((item, index) => {
                            this.props.myShopApiStore.setMaxSideMenuIdx(index);
                            return (
                                <MenuItem item={item} index={index} key={`p_${index}`}/>
                            )
                        })
                    }
                </div>
                <nav className={style.NavArrow} style={{ bottom: '10px' }} hidden={this.state.scroll === this.listRef.current?.scrollHeight - this.listRef.current?.clientHeight}
                    onClick={() => this.listRef.current.scrollBy(0, 100)} 
                     >
                        <SlArrowDown/>
                </nav>
            </div>
        )
    }
}

export default SideMenu;